export const GraphIcon = (props) => {
  const { active } = props;
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.22184 13.9998H2.6665V9.6665C2.6665 9.11422 3.11422 8.6665 3.6665 8.6665H6.22184"
          stroke={active ? "#102A43" : "#627D98"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.22217 3C6.22217 2.44772 6.66988 2 7.22217 2H8.7775C9.32979 2 9.7775 2.44772 9.7775 3V14H6.22217V3Z"
          stroke={active ? "#102A43" : "#627D98"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1 14H15"
          stroke={active ? "#102A43" : "#627D98"}
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <path
          d="M9.77783 5.3335H12.3332C12.8855 5.3335 13.3332 5.78121 13.3332 6.3335V14.0002H9.77783"
          stroke={active ? "#102A43" : "#627D98"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </svg>
  );
};
