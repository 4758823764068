import cn from "classnames";

import { useFetch } from "hooks/fetch";

import s from "./s.module.css";
import { ToolDataByCategoryPerDayECharts } from "./toolDataByCategoryPerDayECharts";
import { DealDataByCategoryPerDayECharts } from "./dealDataByCategoryPerDayECharts";
import { NotaryInvolvementByCategoryPerDayECharts } from "./notaryInvolvementByCategoryPerDayECharts";
import { DcUsageNotaryInvolvementByCategoryPerDayECharts } from "./dcUsageNotaryInvolvementByCategoryPerDayECharts";
import { RetrievabilityDataByCategoryPerDayECharts } from "./retrievabilityDataByCategoryPerDayECharts";
import { AvgTTFB } from "./avgTTFBPerDayECharts";
import { AvgTTFBPerDayCallStatsECharts } from "./avgTTFBPerDayCallStatsECharts";

export default function DashboardTT() {
  const [
    dealDataByCategoryPerDayCumulative,
    { loaded: dealDataByCategoryPerDayCumulativeLoaded },
  ] = useFetch("/timescale/deal-data-by-category/cumulative/per-day");

  const [
    cidCheckerDataByCategoryPerDayCumulative,
    { loaded: cidCheckerDataByCategoryPerDayCumulativeLoaded },
  ] = useFetch("/timescale/cid-checker-data-by-category/cumulative/per-day");

  const [
    dealDataByCategoryPerDay,
    { loaded: dealDataByCategoryPerDayLoaded },
  ] = useFetch("/timescale/deal-data-by-category/per-day");

  const [
    cidCheckerDataByCategoryPerDay,
    { loaded: cidCheckerDataByCategoryPerDayLoaded },
  ] = useFetch("/timescale/cid-checker-data-by-category/per-day");

  const [
    notaryInvolvementByCategoryPerDayCumulative,
    { loaded: notaryInvolvementByCategoryPerDayCumulativeLoaded },
  ] = useFetch("/timescale/notary-involvement-by-category/cumulative/per-day");

  const [
    dcUsageNotaryInvolvementByCategoryPerDayCumulative,
    { loaded: dcUsageNotaryInvolvementByCategoryPerDayCumulativeLoaded },
  ] = useFetch(
    "/timescale/dc-usage-notary-involvement-by-category/cumulative/per-day"
  );

  const [
    retrievabilityDataByCategoryPerDayCumulative,
    { loaded: retrievabilityDataByCategoryPerDayCumulativeLoaded },
  ] = useFetch("/timescale/retrievability-data-by-category/cumulative/per-day");

  const [avgTTFBPerDay, { loaded: avgTTFBPerDayLoaded }] = useFetch(
    "/timescale/average-ttfb/per-day"
  );

  const disabledData = [];
  for (const item of cidCheckerDataByCategoryPerDay) {
    disabledData.push({
      ...item,
      totalCategory1: 25,
      totalCategory2: 25,
      totalCategory3: 25,
      totalCategory4: 25,
    });
  }
  return (
    <div className="container">
      <h2 className="h2">Dashboard</h2>
      <div className={s.grid}>
        <dl className={cn(s.card, s.size6)}>
          {dealDataByCategoryPerDayCumulativeLoaded &&
            dealDataByCategoryPerDayLoaded && (
              <>
                <DealDataByCategoryPerDayECharts
                  title={"Quality of usage of DC"}
                  tooltip={`The graph shows the sum of deal sizes for all clients grouped by
              category. The categories are as follows:
              <br />
              1. All 3 tools say that this client is above 80% percentile OR human override from T&T WG <br />
              2. 2/3 tools say that this client is above 80% percentile  <br />
              3. TBD based on additional analysis <br />
              4. At least 1 of the 3 tools say that this client is below 50%
              percentile  <br />
              5. Human override from
              T&T WG <br />
              <br />`}
                  dealData={{
                    dealDataByCategoryPerDayCumulative,
                    dealDataByCategoryPerDay,
                  }}
                />
                <div className={s.legendContainer}>
                  The graph shows the sum of deal sizes for all clients grouped
                  by category. The categories are as follows:
                  <br />
                  <div>
                    <div className={s.legendRow}>
                      <div className={cn(s.legendName, s.legendColor5)}>
                        Most Likely Compliant Deal-Making
                      </div>
                      All 3 tools say that this client is above 80% percentile
                      OR human override from T&T WG
                    </div>
                    <div className={s.legendRow}>
                      <div className={cn(s.legendName, s.legendColor4)}>
                        Likely Compliant Deal-Making
                      </div>{" "}
                      2/3 tools say that this client is above 80% percentile
                    </div>
                    <div className={s.legendRow}>
                      <div className={cn(s.legendName, s.legendColor3)}>
                        Unknown
                      </div>{" "}
                      TBD based on additional analysis
                    </div>
                    <div className={s.legendRow}>
                      <div className={cn(s.legendName, s.legendColor2)}>
                        Likely Non Compliant Deal-Making
                      </div>{" "}
                      At least 1 of the 3 tools say that this client is below
                      50% percentile
                    </div>
                    <div className={s.legendRow}>
                      <div className={cn(s.legendName, s.legendColor1)}>
                        Most Likely Non Compliant Deal-Making
                      </div>
                      Human override from T&T WG
                    </div>
                  </div>
                </div>
              </>
            )}
        </dl>

        <dl className={cn(s.card, s.size6)}>
          {cidCheckerDataByCategoryPerDayCumulativeLoaded &&
            cidCheckerDataByCategoryPerDayLoaded && (
              <>
                <ToolDataByCategoryPerDayECharts
                  title={"Tool 1 - CID Checker Bot/Deal distribution"}
                  tooltip={`The score of a client represents the percentage of deals
              considered as well distributed by the CID Checker out of the total
              size of deals made by that client.
              <br /> Based on this score each client falls in one of the 4
              categories. The graph shows the sum of deal sizes for all clients
              grouped by category.`}
                  dealData={{
                    toolDataByCategoryPerDayCumulative: cidCheckerDataByCategoryPerDayCumulative,
                    toolDataByCategoryPerDay: cidCheckerDataByCategoryPerDay,
                  }}
                />
                <div className={s.legendContainer}>
                  The score of a client represents the percentage of deals
                  considered as well distributed by the CID Checker out of the
                  total size of deals made by that client.
                  <br />
                  <div>
                    <div className={s.legendRow}>
                      <div className={cn(s.legendName, s.legendColor5)}>
                        75% - 100%
                      </div>
                      Total size of deals from clients with a score between 75%
                      and 100%
                    </div>
                    <div className={s.legendRow}>
                      <div className={cn(s.legendName, s.legendColor4)}>
                        50% - 75%
                      </div>{" "}
                      Total size of deals from clients with a score between 50%
                      and 75%
                    </div>
                    <div className={s.legendRow}>
                      <div className={cn(s.legendName, s.legendColor2)}>
                        25% - 50%
                      </div>{" "}
                      Total size of deals from clients with a score between 25%
                      and 50%
                    </div>
                    <div className={s.legendRow}>
                      <div className={cn(s.legendName, s.legendColor1)}>
                        0% - 25%
                      </div>
                      Total size of deals from clients with a score between 0%
                      and 25%
                    </div>
                  </div>
                </div>
              </>
            )}
        </dl>

        <dl className={cn(s.card, s.size6)}>
          {retrievabilityDataByCategoryPerDayCumulativeLoaded && (
            <>
              <RetrievabilityDataByCategoryPerDayECharts
                title={"Tool 2 - Retrieval bot score"}
                tooltip={``}
                dealData={{
                  retrievabilityDataByCategoryPerDayCumulative,
                }}
              />
              <div className={s.legendContainer}>
                The score of a client represents the percentage of successfull
                attempts made by the retrieval bot using a given method
                <br />
                <div>
                  <div className={s.legendRow}>
                    <div className={cn(s.legendName, s.legendColor5)}>
                      75% - 100%
                    </div>
                    Total size of deals from clients with a score between 75%
                    and 100%
                  </div>
                  <div className={s.legendRow}>
                    <div className={cn(s.legendName, s.legendColor4)}>
                      50% - 75%
                    </div>{" "}
                    Total size of deals from clients with a score between 50%
                    and 75%
                  </div>
                  <div className={s.legendRow}>
                    <div className={cn(s.legendName, s.legendColor2)}>
                      25% - 50%
                    </div>{" "}
                    Total size of deals from clients with a score between 25%
                    and 50%
                  </div>
                  <div className={s.legendRow}>
                    <div className={cn(s.legendName, s.legendColor1)}>
                      1% - 25%
                    </div>
                    Total size of deals from clients with a score between 1% and
                    25%
                  </div>

                  <div className={s.legendRow}>
                    <div className={cn(s.legendName, s.legendColor0)}>0%</div>
                    Total size of deals from clients with a score of 0%
                  </div>
                </div>
              </div>
            </>
          )}
        </dl>

        <dl className={cn(s.card, s.size6)}>
          <dt
            className={s.cardTitle}
            style={{ fontSize: "14px", fontWeight: "bold" }}
          >
            Tool 3 - Reputation score (coming soon)
          </dt>
          <div></div>
        </dl>

        <dl className={cn(s.card, s.size6)}>
          {notaryInvolvementByCategoryPerDayCumulativeLoaded && (
            <>
              <NotaryInvolvementByCategoryPerDayECharts
                title={"DataCap allocated grouped by number of signers"}
                tooltip={``}
                dealData={{
                  notaryInvolvementByCategoryPerDayCumulative,
                }}
              />
              <div className={s.legendContainer}>
                This graph shows the amount of datacap allocated, grouped by
                number of signers that interacted with the datacap request.
                <br />
                <div>
                  <div className={s.legendRow}>
                    <div className={cn(s.legendName, s.legendColor5)}>
                      &gt; 6 signers
                    </div>
                    Total size of allocations to clients that received
                    signatures from more than 6 notaries
                  </div>
                  <div className={s.legendRow}>
                    <div className={cn(s.legendName, s.legendColor4)}>
                      5-6 signers
                    </div>{" "}
                    Total size of allocations to clients that received
                    signatures from 5 - 6 notaries
                  </div>
                  <div className={s.legendRow}>
                    <div className={cn(s.legendName, s.legendColor2)}>
                      2 - 4 signers
                    </div>{" "}
                    Total size of allocations to clients that received
                    signatures from 2 - 4 notaries
                  </div>
                  <div className={s.legendRow}>
                    <div className={cn(s.legendName, s.legendColor1)}>
                      1 signer
                    </div>
                    Total size of allocations to clients that received
                    signatures from 1 notary
                  </div>
                </div>
              </div>
            </>
          )}
        </dl>

        <dl className={cn(s.card, s.size6)}>
          {dcUsageNotaryInvolvementByCategoryPerDayCumulativeLoaded && (
            <>
              <DcUsageNotaryInvolvementByCategoryPerDayECharts
                title={"DataCap used grouped by number of signers"}
                tooltip={``}
                dealData={{
                  dcUsageNotaryInvolvementByCategoryPerDayCumulative,
                }}
              />
              <div className={s.legendContainer}>
                This graph shows the amount of datacap used, grouped by number
                of signers that interacted with the datacap request.
                <br />
                <div>
                  <div className={s.legendRow}>
                    <div className={cn(s.legendName, s.legendColor5)}>
                      &gt; 8 signers
                    </div>
                    Total size of deals from clients that received signatures
                    from more than 8 notaries
                  </div>
                  <div className={s.legendRow}>
                    <div className={cn(s.legendName, s.legendColor4)}>
                      5-8 signers
                    </div>{" "}
                    Total size of deals from clients that received signatures
                    from 5 - 8 notaries
                  </div>
                  <div className={s.legendRow}>
                    <div className={cn(s.legendName, s.legendColor3_1)}>
                      4 signers
                    </div>{" "}
                    Total size of deals from clients that received signatures
                    from 4 notaries
                  </div>
                  <div className={s.legendRow}>
                    <div className={cn(s.legendName, s.legendColor3)}>
                      3 signers
                    </div>{" "}
                    Total size of deals from clients that received signatures
                    from 3 notaries
                  </div>
                  <div className={s.legendRow}>
                    <div className={cn(s.legendName, s.legendColor2)}>
                      2 signers
                    </div>{" "}
                    Total size of deals from clients that received signatures
                    from 2 notaries
                  </div>
                  <div className={s.legendRow}>
                    <div className={cn(s.legendName, s.legendColor1)}>
                      1 signer
                    </div>
                    Total size of deals from clients that received signatures
                    from 1 notary
                  </div>
                </div>
              </div>
            </>
          )}
        </dl>

        <dl className={cn(s.card, s.size6)}>
          {avgTTFBPerDayLoaded && (
            <>
              <AvgTTFB
                title={"Average TTFB"}
                tooltip={``}
                dealData={{
                  avgTTFBPerDay,
                }}
              />
              <div className={s.legendContainer}>
                This graph shows average time to first byte computed using
                retrieval bot data.
                <br />
                <div>
                  <div className={s.legendRow}>
                    <div className={cn(s.legendName, s.legendColor5)}>
                      95th percentile TTFB
                    </div>
                    Average for 95th percentile TTFB
                  </div>
                  <div className={s.legendRow}>
                    <div className={cn(s.legendName, s.legendColor4)}>
                      50th percentile TTFB
                    </div>{" "}
                    Average for 50th percentile TTFB
                  </div>
                </div>
              </div>
            </>
          )}
        </dl>

        <dl className={cn(s.card, s.size6)}>
          {avgTTFBPerDayLoaded && (
            <>
              <AvgTTFBPerDayCallStatsECharts
                title={"Retrieval bot attempts stats"}
                tooltip={``}
                dealData={{
                  avgTTFBPerDay,
                }}
              />
              <div className={s.legendContainer}>
                This graph shows the number of retrieval attempts made by the
                retrieval bot
                <br />
                <div>
                  <div className={s.legendRow}>
                    <div className={cn(s.legendName, s.legendColor5)}>
                      Succesfull attempts
                    </div>
                    Succesfull retrieval attempts made by the retrieval bot
                  </div>
                  <div className={s.legendRow}>
                    <div className={cn(s.legendName, s.legendColor4)}>
                      Total attempts
                    </div>{" "}
                    Total retrieval attempts made by the retrieval bot
                  </div>
                </div>
              </div>
            </>
          )}
        </dl>
      </div>
    </div>
  );
}
