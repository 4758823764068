import { convertBytesToIEC } from "utils/bytes";

import s from "./s.module.css";
import { Radio } from "../Radio";
import { useState } from "react";
import cn from "classnames";
import ReactECharts from "echarts-for-react";
import { GraphIcon } from "components/Svg/graphIcon";
import { PercentIcon } from "components/Svg/percentIcon";
import { Tooltip } from "components/Tooltip";
import { auto } from "@popperjs/core";

export const AvgTTFBPerDayCallStatsECharts = ({ dealData, title, tooltip }) => {
  const extractTabData = (item, prefix) => {
    if (prefix == "total50")
      return {
        day: item.day,
        total: item[`TTFB50pTotalCount`],
        successCount: item[`TTFB50pSuccessCount`],
      };
    if (prefix == "total95")
      return {
        day: item.day,
        total: item[`TTFB95pTotalCount`],
        successCount: item[`TTFB95pSuccessCount`],
      };
    return {
      day: item.day,
      total: item[`${prefix}Total`],
      successCount: item[`${prefix}SuccessTotal`],
    };
  };

  const [filteredData, setFilteredData] = useState(
    dealData.avgTTFBPerDay
      .map((item) => extractTabData(item, "total50"))
      .slice(-90)
  );

  const [showPercentages, setShowPercentages] = useState(false);

  const [selectedTab, setSelectedTab] = useState("total50");

  const [radioFilter, setRadioFilter] = useState({
    option1: false,
    option2: true,
    option3: false,
    option4: false,
    optionMax: false,
  });

  const getTabData = (tab) => {
    if (tab === "total50")
      return dealData.avgTTFBPerDay.map((item) =>
        extractTabData(item, "total50")
      );
    if (tab === "total95")
      return dealData.avgTTFBPerDay.map((item) =>
        extractTabData(item, "total50")
      );
    if (tab === "graphsync")
      return dealData.avgTTFBPerDay.map((item) =>
        extractTabData(item, "graphsync")
      );
    if (tab === "http")
      return dealData.avgTTFBPerDay.map((item) => extractTabData(item, "http"));
    if (tab === "bitswap")
      return dealData.avgTTFBPerDay.map((item) =>
        extractTabData(item, "bitswap")
      );
  };

  const getRadioFilterValueBasedOnTab = (tab, radioFilter) => {
    if (
      tab === "graphsync" ||
      tab === "http" ||
      tab === "bitswap" ||
      tab === "total50" ||
      tab === "total95"
    ) {
      if (radioFilter.option1) return 30;
      if (radioFilter.option2) return 90;
      if (radioFilter.option3) return 180;
      if (radioFilter.option4) return 360;
    }
    return 0;
  };

  const getRadioFilterOptionNameBasedOnTab = (tab, option) => {
    if (
      tab === "graphsync" ||
      tab === "http" ||
      tab === "bitswap" ||
      tab === "total50" ||
      tab === "total95"
    ) {
      if (option === "option1") return "30D";
      if (option === "option2") return "90D";
      if (option === "option3") return "180D";
      if (option === "option4") return "360D";

      return "MAX";
    }

    return "N/A";
  };

  const onFilterButtonClick = (option) => {
    if (
      option != "option1" &&
      option != "option2" &&
      option != "option3" &&
      option != "option4" &&
      option != "optionMax"
    )
      return;
    const filter = {
      option1: false,
      option2: false,
      option3: false,
      option4: false,
      optionMax: false,
    };
    filter[option] = true;

    setRadioFilter(filter);
    setFilteredData(
      getTabData(selectedTab).slice(
        -1 * getRadioFilterValueBasedOnTab(selectedTab, filter)
      )
    );

    const echartInstance = echartRef.getEchartsInstance();
    echartInstance.dispatchAction({
      type: "dataZoom",
      start: 0,
      end: 100,
    });
  };

  const onGraphTypeButtonClick = (newShowPercentagesValue) => {
    setShowPercentages(newShowPercentagesValue);
  };

  const setTab = (tab) => {
    setSelectedTab(tab);
    if (radioFilter.optionMax) {
      setFilteredData(getTabData(tab));
    } else {
      setFilteredData(
        getTabData(tab).slice(
          -1 * getRadioFilterValueBasedOnTab(tab, radioFilter)
        )
      );
    }
  };

  const data = filteredData.map((p) => ({ ...p, name: p.day.split("T")[0] }));

  let echartRef = null;

  const getOption = () => {
    return {
      tooltip: {
        trigger: "axis",
        axisPointer: {
          // Use axis to trigger tooltip
          type: "cross", // 'shadow' as default; can also be 'line' or 'shadow'
          label: {
            formatter: (params) => {
              return params.value;
            },
          },
        },
        valueFormatter: (val) => val,
      },
      legend: {},
      grid: {
        left: "3%",
        right: "4%",
        bottom: "35",
        containLabel: true,
      },
      xAxis: {
        type: "category",
        data: data.map((e) => e.name),
      },
      yAxis: {
        type: "value",
        axisLabel: {
          formatter: (val) => val,
        },
      },
      dataZoom: [
        {
          type: "slider",
          showDetail: false,
          bottom: "8px",
          moveHandleStyle: { opacity: 0 },
          start: 0,
          end: 100,
        },
      ],
      series: [
        {
          name: "Total attempts",
          type: "bar",
          stack: "total",
          emphasis: {
            itemStyle: {
              opacity: 0.5,
            },
          },
          itemStyle: {
            color: "#34D399",
          },
          data: data.map((e) => e["total"]),
        },
        {
          name: "Succesfull attempts",
          type: "bar",
          stack: "success",
          emphasis: {
            itemStyle: {
              opacity: 0.5,
            },
          },
          itemStyle: {
            color: "#059669",
          },
          data: data.map((e) => e["successCount"]),
        },
      ],
    };
  };

  return (
    <div className={s.chartWrap}>
      <div className={s.chartHeader}>
        <div className={s.headerRow1}>
          <div className={s.chartTitle}>
            {title}
            {/*({!showPercentages ? "by total size" : "by percentage"}) */}
            {/* <Tooltip>
              <div dangerouslySetInnerHTML={{ __html: tooltip }}></div>
            </Tooltip> */}
          </div>
          <div className={s.chartFilterWrapper}>
            {/* <div className={s.chartFilters}>
              <button
                className={cn(
                  {
                    [s.chartFilterButtonActive]: !showPercentages,
                  },
                  s.chartFilterButton
                )}
                onClick={() => {
                  onGraphTypeButtonClick(false);
                }}
              >
                <Tooltip target={<GraphIcon active={!showPercentages} />}>
                  Graphs by deal size
                </Tooltip>
              </button>

              <button
                className={cn(
                  {
                    [s.chartFilterButtonActive]: showPercentages,
                  },
                  s.chartFilterButton
                )}
                onClick={() => {
                  onGraphTypeButtonClick(true);
                }}
              >
                <Tooltip target={<PercentIcon active={showPercentages} />}>
                  Graphs by percentages
                </Tooltip>
              </button>
            </div> */}

            <div className={s.chartFilters}>
              <button
                className={cn(
                  {
                    [s.chartFilterButtonActive]: radioFilter.option1,
                  },
                  s.chartFilterButton
                )}
                onClick={() => {
                  onFilterButtonClick("option1");
                }}
              >
                {getRadioFilterOptionNameBasedOnTab(selectedTab, "option1")}
              </button>

              <button
                className={cn(
                  {
                    [s.chartFilterButtonActive]: radioFilter.option2,
                  },
                  s.chartFilterButton
                )}
                onClick={() => {
                  onFilterButtonClick("option2");
                }}
              >
                {getRadioFilterOptionNameBasedOnTab(selectedTab, "option2")}
              </button>

              <button
                className={cn(
                  {
                    [s.chartFilterButtonActive]: radioFilter.option3,
                  },
                  s.chartFilterButton
                )}
                onClick={() => {
                  onFilterButtonClick("option3");
                }}
              >
                {getRadioFilterOptionNameBasedOnTab(selectedTab, "option3")}
              </button>

              <button
                className={cn(
                  {
                    [s.chartFilterButtonActive]: radioFilter.option4,
                  },
                  s.chartFilterButton
                )}
                onClick={() => {
                  onFilterButtonClick("option4");
                }}
              >
                {getRadioFilterOptionNameBasedOnTab(selectedTab, "option4")}
              </button>

              <button
                className={cn(
                  {
                    [s.chartFilterButtonActive]: radioFilter.optionMax,
                  },
                  s.chartFilterButton
                )}
                onClick={() => {
                  onFilterButtonClick("optionMax");
                }}
              >
                {getRadioFilterOptionNameBasedOnTab(selectedTab, "optionmMax")}
              </button>
            </div>
          </div>
        </div>

        <div className={s.headerRow2}>
          <button
            className={cn(s.chartTabButton, {
              [s.chartTabButtonActive]: selectedTab === "total50",
            })}
            onClick={() => setTab("total50")}
          >
            Total
            <div style={{ marginLeft: "-10px", marginTop: "2px" }}>
              <Tooltip>
                <div style={{ maxWidth: "800px" }}>
                  The attempts stats in this graph for a given day is the same
                  as the attempts stats for the method with the lowest TTFB. For
                  example, for a given day if the method with the lowest TTFB is
                  HTTP we will use for that day the attemps stats of the HTTP
                  method.
                </div>
              </Tooltip>
            </div>
          </button>
          {/* <button
            className={cn(s.chartTabButton, {
              [s.chartTabButtonActive]: selectedTab === "total95",
            })}
            onClick={() => setTab("total95")}
          >
            Total 95p
          </button> */}
          <button
            className={cn(s.chartTabButton, {
              [s.chartTabButtonActive]: selectedTab === "graphsync",
            })}
            onClick={() => setTab("graphsync")}
          >
            Graphsync
          </button>
          <button
            className={cn(s.chartTabButton, {
              [s.chartTabButtonActive]: selectedTab === "http",
            })}
            onClick={() => setTab("http")}
          >
            HTTP
          </button>
          <button
            className={cn(s.chartTabButton, {
              [s.chartTabButtonActive]: selectedTab === "bitswap",
            })}
            onClick={() => setTab("bitswap")}
          >
            Bitswap
          </button>
        </div>
      </div>

      <div style={{ padding: "20px" }}>
        <ReactECharts
          ref={(e) => {
            echartRef = e;
          }}
          option={getOption()}
          notMerge={true}
          lazyUpdate={true}
          style={{
            height: "350px",
            width: "100%",
          }}
        />
      </div>

      {selectedTab === "total50" && (
        <div
          className={s.legendContainer}
          style={{ maxWidth: "800px", marginLeft: auto, marginRight: auto }}
        >
          The attempts stats in this graph for a given day is the same as the
          attempts stats for the method with the lowest TTFB. For example, for a
          given day if the method with the lowest TTFB is HTTP we will use for
          that day the attemps stats of the HTTP method.
          <br />
          <br />
        </div>
      )}
    </div>
  );
};
