export const PercentIcon = (props) => {
  const { active } = props;
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.21875 9.78072L9.78023 6.21924"
        stroke={active ? "#102A43" : "#627D98"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.94058 9.6557C10.0169 9.73448 10.0155 9.86 9.93757 9.93713C9.85961 10.0143 9.73409 10.0143 9.65613 9.93713C9.57817 9.86 9.57683 9.73448 9.65312 9.6557C9.69081 9.61678 9.74268 9.59481 9.79685 9.59481C9.85103 9.59481 9.90289 9.61678 9.94058 9.6557"
        stroke={active ? "#102A43" : "#627D98"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.34585 6.05658C6.42215 6.13536 6.42081 6.26088 6.34285 6.33801C6.26489 6.41514 6.13936 6.41514 6.0614 6.33801C5.98344 6.26088 5.9821 6.13536 6.0584 6.05658C6.09609 6.01766 6.14795 5.99569 6.20212 5.99569C6.2563 5.99569 6.30816 6.01766 6.34585 6.05658"
        stroke={active ? "#102A43" : "#627D98"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x="1.99756"
        y="1.99756"
        width="12.005"
        height="12.005"
        rx="4"
        stroke={active ? "#102A43" : "#627D98"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
