import { lazy, Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import { Spinner } from "components/Spinner";
import { Header } from "components/Header";
import DashboardTT from "components/DashboardTT";

const ClientsPage = lazy(() => import("components/ClientsPage"));
const MinersPage = lazy(() => import("components/MinersPage"));

function App() {
  return (
    <>
      <Header />
      <main>
        <Suspense
          fallback={
            <div style={{ margin: "auto" }}>
              <Spinner width={40} height={40} />
            </div>
          }
        >
          <Switch>
            <Route exact path="/" component={DashboardTT} />
            <Route exact path="/providers" component={MinersPage} />
            <Route exact path="/clients" component={ClientsPage} />

            <Redirect to="/" />
          </Switch>
        </Suspense>
      </main>
    </>
  );
}

export default App;
