import { convertBytesToIEC } from "utils/bytes";

import s from "./s.module.css";
import { Radio } from "../Radio";
import { useState } from "react";
import cn from "classnames";
import ReactECharts from "echarts-for-react";
import { GraphIcon } from "components/Svg/graphIcon";
import { PercentIcon } from "components/Svg/percentIcon";
import { Tooltip } from "components/Tooltip";

export const ToolDataByCategoryPerDayECharts = ({
  dealData,
  title,
  tooltip,
}) => {
  const [filteredData, setFilteredData] = useState(
    dealData.toolDataByCategoryPerDayCumulative.slice(-90)
  );

  const [showPercentages, setShowPercentages] = useState(false);

  const [selectedTab, setSelectedTab] = useState(
    "toolDataByCategoryPerDayCumulative"
  );

  const [radioFilter, setRadioFilter] = useState({
    option1: false,
    option2: true,
    option3: false,
    option4: false,
    optionMax: false,
  });

  const getTabData = (tab) => {
    if (tab === "toolDataByCategoryPerDayCumulative")
      return dealData.toolDataByCategoryPerDayCumulative;
    if (tab === "toolDataByCategoryPerDay")
      return dealData.toolDataByCategoryPerDay;
  };

  const getRadioFilterValueBasedOnTab = (tab, radioFilter) => {
    if (
      tab === "toolDataByCategoryPerDayCumulative" ||
      tab === "toolDataByCategoryPerDay"
    ) {
      if (radioFilter.option1) return 30;
      if (radioFilter.option2) return 90;
      if (radioFilter.option3) return 180;
      if (radioFilter.option4) return 360;
    }
    return 0;
  };

  const getRadioFilterOptionNameBasedOnTab = (tab, option) => {
    if (
      tab === "toolDataByCategoryPerDayCumulative" ||
      tab === "toolDataByCategoryPerDay"
    ) {
      if (option === "option1") return "30D";
      if (option === "option2") return "90D";
      if (option === "option3") return "180D";
      if (option === "option4") return "360D";

      return "MAX";
    }

    return "N/A";
  };

  const onFilterButtonClick = (option) => {
    if (
      option != "option1" &&
      option != "option2" &&
      option != "option3" &&
      option != "option4" &&
      option != "optionMax"
    )
      return;
    const filter = {
      option1: false,
      option2: false,
      option3: false,
      option4: false,
      optionMax: false,
    };
    filter[option] = true;

    setRadioFilter(filter);
    setFilteredData(
      getTabData(selectedTab).slice(
        -1 * getRadioFilterValueBasedOnTab(selectedTab, filter)
      )
    );

    const echartInstance = echartRef.getEchartsInstance();
    echartInstance.dispatchAction({
      type: "dataZoom",
      start: 0,
      end: 100,
    });
  };

  const onGraphTypeButtonClick = (newShowPercentagesValue) => {
    setShowPercentages(newShowPercentagesValue);
  };

  const setTab = (tab) => {
    setSelectedTab(tab);
    if (radioFilter.optionMax) {
      setFilteredData(getTabData(tab));
    } else {
      setFilteredData(
        getTabData(tab).slice(
          -1 * getRadioFilterValueBasedOnTab(tab, radioFilter)
        )
      );
    }
  };

  const data = filteredData.map((p) => ({ ...p, name: p.day.split("T")[0] }));

  let echartRef = null;

  const getOption = () => {
    return {
      tooltip: {
        trigger: "axis",
        axisPointer: {
          // Use axis to trigger tooltip
          type: "cross", // 'shadow' as default; can also be 'line' or 'shadow'
          label: {
            formatter: (params) => {
              if (params.axisDimension === "y")
                return !showPercentages
                  ? convertBytesToIEC(params.value)
                  : `${Math.round(params.value * 100) / 100} %`;
              return params.value;
            },
          },
        },
        valueFormatter: (val) =>
          !showPercentages ? convertBytesToIEC(val) : `${val} %`,
      },
      legend: {},
      grid: {
        left: "3%",
        right: "4%",
        bottom: "35",
        containLabel: true,
      },
      xAxis: {
        type: "category",
        data: data.map((e) => e.name),
      },
      yAxis: {
        type: "value",
        axisLabel: {
          formatter: (val) =>
            !showPercentages ? convertBytesToIEC(val) : `${val} %`,
        },
      },
      dataZoom: [
        {
          type: "slider",
          showDetail: false,
          bottom: "8px",
          moveHandleStyle: { opacity: 0 },
          start: 0,
          end: 100,
        },
      ],
      series: [
        {
          name: "0% - 25%",
          type: "bar",
          stack: "total",
          emphasis: {
            itemStyle: {
              opacity: 0.5,
            },
          },
          itemStyle: {
            color: "#F97316",
          },
          data: data.map(
            (e) => e[!showPercentages ? "totalCategory1" : "percentCategory1"]
          ),
        },
        {
          name: "25% - 50%",
          type: "bar",
          stack: "total",
          emphasis: {
            itemStyle: {
              opacity: 0.5,
            },
          },
          itemStyle: {
            color: "#FBBF24",
          },
          data: data.map(
            (e) => e[!showPercentages ? "totalCategory2" : "percentCategory2"]
          ),
        },
        {
          name: "50% - 75%",
          type: "bar",
          stack: "total",
          emphasis: {
            itemStyle: {
              opacity: 0.5,
            },
          },
          itemStyle: {
            color: "#34D399",
          },
          data: data.map(
            (e) => e[!showPercentages ? "totalCategory3" : "percentCategory3"]
          ),
        },
        {
          name: "75% - 100%",
          type: "bar",
          stack: "total",
          emphasis: {
            itemStyle: {
              opacity: 0.5,
            },
          },
          itemStyle: {
            color: "#059669",
          },
          data: data.map(
            (e) => e[!showPercentages ? "totalCategory4" : "percentCategory4"]
          ),
        },
      ],
    };
  };

  return (
    <div className={s.chartWrap}>
      <div className={s.chartHeader}>
        <div className={s.headerRow1}>
          <div className={s.chartTitle}>
            {title} ({!showPercentages ? "by total size" : "by percentage"})
            <Tooltip>
              <div dangerouslySetInnerHTML={{ __html: tooltip }}></div>
            </Tooltip>
          </div>
          <div className={s.chartFilterWrapper}>
            <div className={s.chartFilters}>
              <button
                className={cn(
                  {
                    [s.chartFilterButtonActive]: !showPercentages,
                  },
                  s.chartFilterButton
                )}
                onClick={() => {
                  onGraphTypeButtonClick(false);
                }}
              >
                <Tooltip target={<GraphIcon active={!showPercentages} />}>
                  Graphs by deal size
                </Tooltip>
              </button>

              <button
                className={cn(
                  {
                    [s.chartFilterButtonActive]: showPercentages,
                  },
                  s.chartFilterButton
                )}
                onClick={() => {
                  onGraphTypeButtonClick(true);
                }}
              >
                <Tooltip target={<PercentIcon active={showPercentages} />}>
                  Graphs by percentages
                </Tooltip>
              </button>
            </div>

            <div className={s.chartFilters}>
              <button
                className={cn(
                  {
                    [s.chartFilterButtonActive]: radioFilter.option1,
                  },
                  s.chartFilterButton
                )}
                onClick={() => {
                  onFilterButtonClick("option1");
                }}
              >
                {getRadioFilterOptionNameBasedOnTab(selectedTab, "option1")}
              </button>

              <button
                className={cn(
                  {
                    [s.chartFilterButtonActive]: radioFilter.option2,
                  },
                  s.chartFilterButton
                )}
                onClick={() => {
                  onFilterButtonClick("option2");
                }}
              >
                {getRadioFilterOptionNameBasedOnTab(selectedTab, "option2")}
              </button>

              <button
                className={cn(
                  {
                    [s.chartFilterButtonActive]: radioFilter.option3,
                  },
                  s.chartFilterButton
                )}
                onClick={() => {
                  onFilterButtonClick("option3");
                }}
              >
                {getRadioFilterOptionNameBasedOnTab(selectedTab, "option3")}
              </button>

              <button
                className={cn(
                  {
                    [s.chartFilterButtonActive]: radioFilter.option4,
                  },
                  s.chartFilterButton
                )}
                onClick={() => {
                  onFilterButtonClick("option4");
                }}
              >
                {getRadioFilterOptionNameBasedOnTab(selectedTab, "option4")}
              </button>

              <button
                className={cn(
                  {
                    [s.chartFilterButtonActive]: radioFilter.optionMax,
                  },
                  s.chartFilterButton
                )}
                onClick={() => {
                  onFilterButtonClick("optionMax");
                }}
              >
                {getRadioFilterOptionNameBasedOnTab(selectedTab, "optionmMax")}
              </button>
            </div>
          </div>
        </div>

        <div className={s.headerRow2}>
          <button
            className={cn(s.chartTabButton, {
              [s.chartTabButtonActive]:
                selectedTab === "toolDataByCategoryPerDayCumulative",
            })}
            onClick={() => setTab("toolDataByCategoryPerDayCumulative")}
          >
            Total
          </button>
          <button
            className={cn(s.chartTabButton, {
              [s.chartTabButtonActive]:
                selectedTab === "toolDataByCategoryPerDay",
            })}
            onClick={() => setTab("toolDataByCategoryPerDay")}
          >
            Daily
          </button>
        </div>
      </div>

      <div style={{ padding: "20px" }}>
        <ReactECharts
          ref={(e) => {
            echartRef = e;
          }}
          option={getOption()}
          notMerge={true}
          lazyUpdate={true}
          style={{
            height: "350px",
            width: "100%",
          }}
        />
      </div>
    </div>
  );
};
